<template>
  <div class="privacy-policy-card">
    <v-card tile elevation="0">
      <v-banner class="justify-center" sticky color="white">
        <div class="text-h4">{{ $t("__privacy_policy") }}</div>
      </v-banner>
      <v-card-text class="privacy-policy-card__text">
        <div class="content">
          chia會保存所有使用者資訊，包含使用者到訪過的頁面、對話內容、問卷內容、以及註冊資訊。這些資訊包含您的個人資料，例如地址、電話等等。在沒有您的書面許可時，我們無法洩漏您的個人資料。只有在使用您所需要的服務時，chia會向您索取特定的資訊。我們保存的資訊不會用來分享、販售或出租。除了以下情形，在您使用
          chia 同時，也同時提供您的資訊給我們：
        </div>
        <div class="content">
          1. 當 chia
          被收購或與其他公司合併時，我們將會轉移您的資訊。在此情況下，我們會以電子郵件，或在
          chia 網站上通知您，您將配合新訂定的隱私條款。
        </div>
        <div class="content">
          2. chia
          交由其他公司執行特定事項時，可能需要和其他公司分享您的資料，以便提供您所需要的產品或服務。在您的允許下，chia
          可以分享您的資訊，以提供您所需要的產品或服務。
        </div>
        <div class="content">
          3. chia
          可能會提供您的個人資料以便協助調查、預防非法行動，或對非法活動採取行動。非法活動包含涉嫌詐欺、涉及潛在威脅他人人身安全、侵犯
          chia 使用條款，或其他法律規定情形。
        </div>
        <div class="content">請仔細閱讀服務條款以保護您的權益。</div>
        <div class="content sub-title">第一條：我們將您的資料做為何種用途</div>
        <div class="content">
          當您註冊時，即代表您將個人資料提供給chia、並同意chia使用。結帳時，我們會取得並使用您的信用卡資料。除非顧客允許，否則您的顧客資料僅能用於chia相關的聯繫。
        </div>
        <div class="content sub-title">第二條：安全性</div>
        <div class="content">
          我們會對您的信用卡資料和特殊訊息進行加密，但無法確保您個人資料100%的安全性，若有任何疑問，請透過電子郵件
          service@chia.com 與我們聯絡。
        </div>
        <div class="content sub-title">第三條：資料外流</div>
        <div class="content">
          在特殊必要情況下，我們會將您的資料交由chia合作的公司。
        </div>
        <div class="content sub-title">第四條：客戶端客資料儲存</div>
        <div class="content">
          chia
          擁有資料庫，然而我們沒有權利進入您的資料。確保您對資料的權利，我們不會直接與您的客戶聯絡，或將您的資料用商業用途。
        </div>
        <div class="content sub-title">第五條：Cookie</div>
        <div class="content">
          為了自動辨識您的身分，我們會儲存一筆 cookie 在您的電腦。chia
          擁有再營銷工具可以記錄您的到訪記錄並提供您相關的廣告。
        </div>
        <div class="content sub-title">第六條：對隱私條款的更動</div>
        <div class="content">
          chia
          保留在任何時間對隱私條款做更動的權利，請您經常閱讀最新的隱私條款。若有任何重大變更，我們會在此通知您。
        </div>
        <div class="content sub-title">第七條：免責聲明</div>
        <div class="content">
          chia
          將盡最大努力維護資料之安全性，但不保證提供不間斷、即時、安全、無錯誤的儲存服務，chia
          不保證本服務之絕對安全性及無瑕疵無失誤，如因 chia
          與相關網路系統及硬體設備之故障、失靈或人為操作上之疏失、駭客入侵等，而導致用戶無法傳輸、使用、或造成任何用戶資料內容（包括會員資料、文字、數字、圖片、聲音、影像）遺失、洩漏、缺漏、更新延誤或儲存上之錯誤，chia
          均不負任何責任，用戶不得向 chia 請求賠償。
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyCard"
};
</script>

<style lang="scss">
.content {
  margin-block: 1em;
  font-size: 20px;
  line-height: 1.3;
}
.sub-title {
  color: black;
  font-weight: bold;
}
</style>
