<template>
  <v-container>
    <v-row
      :class="{ 'flex-column': isMobile, 'flex-column-reverse': isMobile }"
    >
      <v-col :cols="isMobile ? 12 : 4">
        <v-card flat>
          <v-card-title class="justify-center">
            <v-img
              max-width="128px"
              :src="
                require('@/assets/logo/chia-logo-shopping-cart-circle-128.png')
              "
            />
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <!-- e-mail -->
              <div class="subtitle-1 mb-2">
                {{ $t("__email_address") }}
                <span class="red--text">*</span>
              </div>
              <v-text-field
                outlined
                dense
                v-model="form.email"
                type="email"
                :rules="[requiredRule, emailRule]"
                required
              />
              <!-- password -->
              <div class="subtitle-1 mb-2">
                {{ $t("__password") }}
                <span class="red--text">*</span>
              </div>
              <v-text-field
                outlined
                dense
                v-model="form.password"
                type="password"
                :rules="[requiredRule]"
                required
              />
              <!-- confirm password -->
              <div class="subtitle-1 mb-2">
                {{ $t("__confirm_password") }}
                <span class="red--text">*</span>
              </div>
              <v-text-field
                outlined
                dense
                v-model="form.confirmPassword"
                type="password"
                :rules="[requiredRule, confirmPasswordRule]"
                required
              />
              <!-- name -->
              <v-container fluid>
                <v-row>
                  <v-col class="pa-0" :cols="isMobile ? 12 : 5.5">
                    <div class="subtitle-1 mb-2">
                      {{ $t("__first_name") }}
                      <span class="red--text">*</span>
                    </div>
                    <v-text-field
                      outlined
                      dense
                      v-model="form.firstName"
                      type="text"
                      :rules="[requiredRule]"
                      required
                    />
                  </v-col>
                  <v-col class="pa-0" :cols="isMobile ? 0 : 1" />
                  <v-col class="pa-0" :cols="isMobile ? 12 : 5.5">
                    <div class="subtitle-1 mb-2">
                      {{ $t("__last_name") }} <span class="red--text">*</span>
                    </div>
                    <v-text-field
                      outlined
                      dense
                      v-model="form.lastName"
                      type="text"
                      :rules="[requiredRule]"
                      required
                    />
                  </v-col>
                </v-row>
              </v-container>
              <!-- phone number -->
              <div class="subtitle-1 mb-2">
                {{ $t("__phone_number") }}
                <span class="red--text">*</span>
              </div>
              <phone-number-input
                outlined
                dense
                v-model="form.phoneNumber"
                :rules="[requiredRule]"
                required
              />
              <!-- invitation code -->
              <v-checkbox
                :label="$t('__invitation_code_checkbox')"
                v-model="haveInvitationCode"
                @change="form.invitationCode = ''"
              />
              <v-text-field
                :label="$t('__invitation_code')"
                class="invitation-code-input"
                outlined
                dense
                v-model="form.invitationCode"
                type="text"
                :rules="[invitationCodeRule]"
                required
                @keyup="uppercaseInvitationCode"
                v-if="haveInvitationCode"
              />
              <!-- error message -->
              <div class="subtitle-1 mb-2 error--text">{{ error }}</div>
            </v-form>
            <!-- privacy policy -->
            <v-row class="ma-0" align="center">
              <v-checkbox v-model="accepted" />
              <div class="subtitle-1">
                {{ $t("__sign_up_accept_message") }}
                <a @click="privacyPolicyShow = true">
                  {{ $t("__privacy_policy") }}
                </a>
              </div>
            </v-row>
            <v-dialog
              max-width="600px"
              v-model="privacyPolicyShow"
              :fullscreen="isMobile"
            >
              <v-card tile elevation="0">
                <PrivacyPolicyCard />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    @click="
                      privacyPolicyShow = false;
                      accepted = true;
                    "
                    >{{ $t("__accept") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- sign up -->
            <v-btn
              block
              color="success"
              @click="signUp"
              :disabled="!accepted || !valid"
            >
              {{ $t("__sign_up") }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="isMobile ? 12 : 8">
        <v-card flat>
          <v-card-title class="justify-center">
            <h2 class="text-h4 font-weight-bold">{{ showPlan.title }}</h2>
          </v-card-title>
          <v-card-subtitle class="text-center">
            <h2 class="text-h4 font-weight-bold primary--text">
              {{ showPlan.price }}
            </h2>
          </v-card-subtitle>
          <v-card-text class="black--text text-h6">
            <v-sheet outlined class="pa-2 my-5">
              <div class="text-h5 primary--text font-weight-bold">
                {{ $t("__plan_e-platform") }}
              </div>
              <template v-for="(feature, i) of showPlan.eplatForm">
                <div :key="`feature-${i}`" v-if="feature.enabled">
                  <span>
                    {{ $t(`__plan_e-platform_${feature.title}`) }}
                  </span>
                </div>
              </template>
              <v-divider class="my-2"></v-divider>
              <div class="red--text">
                2022年新增功能:
                <template v-for="(feature, i) of showPlan.eplatForm">
                  <div :key="`feature-${i}`" v-if="!feature.enabled">
                    <span class="text-subtitle-1">
                      {{ $t(`__plan_e-platform_${feature.title}`) }}
                    </span>
                  </div>
                </template>
              </div>
            </v-sheet>
            <v-sheet outlined class="pa-2">
              <div class="text-h5 primary--text font-weight-bold">
                {{ $t("__plan_payment_method") }}
              </div>

              <template v-for="(feature, i) of showPlan.paymentMethod">
                <div :key="`feature-${i}`" v-if="feature.enabled">
                  <span>
                    {{ $t(`__plan_payment_method_${feature.title}`) }}
                  </span>
                </div>
              </template>
              <v-divider class="my-2"></v-divider>
              <div class="red--text">
                2022年新增功能:
                <template v-for="(feature, i) of showPlan.paymentMethod">
                  <div :key="`feature-${i}`" v-if="!feature.enabled">
                    <span class="text-subtitle-1">
                      {{ $t(`__plan_payment_method_${feature.title}`) }}
                    </span>
                  </div>
                </template>
              </div>
            </v-sheet>
            <v-sheet outlined class="pa-2 my-5">
              <div class="text-h5 primary--text font-weight-bold">
                {{ $t("__plan_shipping_channel") }}
              </div>

              <template v-for="(feature, i) of showPlan.shippingChannel">
                <div :key="`feature-${i}`" v-if="feature.enabled">
                  <span>
                    {{ $t(`__plan_shipping_channel_${feature.title}`) }}
                  </span>
                </div>
              </template>
              <v-divider class="my-2"></v-divider>
              <div class="red--text">
                2022年新增功能:
                <template v-for="(feature, i) of showPlan.shippingChannel">
                  <div :key="`feature-${i}`" v-if="!feature.enabled">
                    <span class="text-subtitle-1">
                      {{ $t(`__plan_shipping_channel_${feature.title}`) }}
                    </span>
                  </div>
                </template>
              </div>
            </v-sheet>
            <v-sheet outlined class="pa-2 my-5">
              <div class="text-h5 primary--text font-weight-bold">
                {{ $t("__plan_backend_manage") }}
              </div>

              <template v-for="(feature, i) of showPlan.backendManage">
                <div :key="`feature-${i}`" v-if="feature.enabled">
                  <span>
                    {{ $t(`__plan_backend_manage_${feature.title}`) }}
                  </span>
                  <span v-if="feature.limit">
                    : {{ $t("__plan_backend_manage_unlimited") }}
                  </span>
                </div>
              </template>
              <v-divider class="my-2"></v-divider>
              <div class="red--text">
                2022年新增功能:
                <template v-for="(feature, i) of showPlan.backendManage">
                  <div :key="`feature-${i}`" v-if="!feature.enabled">
                    <span class="text-subtitle-1">
                      {{ $t(`__plan_backend_manage_${feature.title}`) }}
                    </span>
                  </div>
                </template>
              </div>
            </v-sheet>
            <v-sheet outlined class="pa-2 my-5">
              <div class="text-h5 primary--text font-weight-bold">
                {{ $t("__plan_consumer_frontend") }}
              </div>

              <template v-for="(feature, i) of showPlan.consumerFrontend">
                <div :key="`feature-${i}`" v-if="feature.enabled">
                  <span>
                    {{ $t(`__plan_consumer_frontend_${feature.title}`) }}
                  </span>
                </div>
              </template>
              <v-divider class="my-2"></v-divider>
              <div class="red--text">
                2022年新增功能:
                <template v-for="(feature, i) of showPlan.consumerFrontend">
                  <div :key="`feature-${i}`" v-if="!feature.enabled">
                    <span class="text-subtitle-1">
                      {{ $t(`__plan_consumer_frontend_${feature.title}`) }}
                    </span>
                  </div>
                </template>
              </div>
            </v-sheet>
            <v-sheet outlined class="pa-2 my-5">
              <div class="text-h5 primary--text font-weight-bold">
                {{ $t("__plan_promotion") }}
              </div>

              <template v-for="(feature, i) of showPlan.promotion">
                <div :key="`feature-${i}`" v-if="feature.enabled">
                  <span>
                    {{ $t(`__plan_promotion_${feature.title}`) }}
                  </span>
                </div>
              </template>
              <v-divider class="my-2"></v-divider>
              <div class="red--text">
                2022年新增功能:
                <template v-for="(feature, i) of showPlan.promotion">
                  <div :key="`feature-${i}`" v-if="!feature.enabled">
                    <span class="text-subtitle-1">
                      {{ $t(`__plan_promotion_${feature.title}`) }}
                    </span>
                  </div>
                </template>
              </div>
            </v-sheet>
            <v-sheet outlined class="pa-2 my-5" v-if="showPlan.ad_analytics">
              <div class="text-h5 primary--text font-weight-bold">
                {{ $t("__plan_ad_analytics") }}
              </div>

              <template v-for="(feature, i) of showPlan.ad_analytics">
                <div :key="`feature-${i}`" v-if="feature.enabled">
                  <span>
                    {{ $t(`__plan_ad_analytics_${feature.title}`) }}
                  </span>
                </div>
              </template>
              <v-divider class="my-2"></v-divider>
              <div class="red--text">
                2022年新增功能:
                <template v-for="(feature, i) of showPlan.ad_analytics">
                  <div :key="`feature-${i}`" v-if="!feature.enabled">
                    <span class="text-subtitle-1">
                      {{ $t(`__plan_ad_analytics_${feature.title}`) }}
                    </span>
                  </div>
                </template>
              </div>
            </v-sheet>
            <v-sheet outlined class="pa-2 my-5">
              <div class="text-h5 primary--text font-weight-bold">
                {{ $t("__plan_analytics") }}
              </div>

              <template v-for="(feature, i) of showPlan.analytics">
                <div :key="`feature-${i}`" v-if="feature.enabled">
                  <span>
                    {{ $t(`__plan_analytics_${feature.title}`) }}
                  </span>
                </div>
              </template>
              <v-divider class="my-2"></v-divider>
              <div class="red--text">
                2022年新增功能:
                <template v-for="(feature, i) of showPlan.analytics">
                  <div :key="`feature-${i}`" v-if="!feature.enabled">
                    <span class="text-subtitle-1">
                      {{ $t(`__plan_analytics_${feature.title}`) }}
                    </span>
                  </div>
                </template>
              </div>
            </v-sheet>
            <v-sheet outlined class="pa-2 my-5">
              <div class="text-h5 primary--text font-weight-bold">
                {{ $t("__plan_telegram_bot") }}
              </div>

              <template v-for="(feature, i) of showPlan.telegramBot">
                <div :key="`feature-${i}`" v-if="feature.enabled">
                  <span>
                    {{ $t(`__plan_telegram_bot_${feature.title}`) }}
                  </span>
                  <span v-if="feature.limit">
                    : {{ $t(`__plan_telegram_bot_${feature.limit}`) }}
                  </span>
                  <span v-if="feature.discount">
                    : {{ $t(`__plan_telegram_bot_${feature.discount}`) }}
                  </span>
                </div>
              </template>
              <v-divider class="my-2"></v-divider>
              <div class="red--text">
                2022年新增功能:
                <template v-for="(feature, i) of showPlan.telegramBot">
                  <div :key="`feature-${i}`" v-if="!feature.enabled">
                    <span class="text-subtitle-1">
                      {{ $t(`__plan_telegram_bot_${feature.title}`) }}
                    </span>
                  </div>
                </template>
              </div>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { userAPI } from "@/plugins/service";
import PrivacyPolicyCard from "@/components/PrivacyPolicyCard";

export default {
  name: "PriceIntro",
  components: {
    PrivacyPolicyCard
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        invitationCode: ""
      },
      accepted: false,
      privacyPolicyShow: false,
      valid: false,
      error: "",
      haveInvitationCode: false,
      requiredRule: v => !!v || this.$t("__required"),
      emailRule: v => /.+@.+\..+/.test(v) || this.$t("__email_address_invalid"),
      confirmPasswordRule: v =>
        v === this.form.password || this.$t("__confirm_password_not_same"),
      invitationCodeRule: v =>
        !v || /\b[0-9A-Z]{6}\b/.test(v) || this.$t("__invitation_code_invalid"),

      personalFreePlan: {
        title: this.$t("__plan_personal_free_title"),
        eplatForm: [
          { title: "chatroom", enabled: true },
          { title: "onepageweb", enabled: true },
          { title: "backend_manage", enabled: true },
          { title: "chia_domain", enabled: true },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_ad", enabled: false },
          { title: "onepageweb_home", enabled: false }
        ],
        paymentMethod: [
          { title: "cash", enabled: true },
          { title: "cash_on_delivery", enabled: true },
          { title: "ATM", enabled: true }
        ],
        shippingChannel: [
          { title: "pickup", enabled: true },
          { title: "logistics", enabled: true },
          { title: "post", enabled: false },

          { title: "virtual_item", enabled: true },
          { title: "logistics_url_setting", enabled: true }
        ],
        consumerFrontend: [
          { title: "muti_language", enabled: true },
          { title: "rwd", enabled: true },
          { title: "live_video", enabled: false },
          { title: "onepageweb", enabled: true },
          { title: "promotion_setting", enabled: false },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_activities", enabled: false },
          { title: "telegram_store", enabled: true }
        ],
        backendManage: [
          { title: "telegram_login", enabled: false },
          {
            title: "publish_item_limit",
            enabled: true,
            limit: "personal_free"
          },
          { title: "rwd", enabled: true },

          { title: "ssl", enabled: true },
          { title: "promotion_notify", enabled: true },
          { title: "consumer_privacy", enabled: true },
          { title: "item_setting", enabled: true },
          { title: "seo_setting", enabled: true },
          { title: "order_notify", enabled: true }
        ],
        promotion: [
          { title: "promotion_time", enabled: false },

          { title: "free_shipping", enabled: false },

          { title: "price_discount", enabled: false }
        ],

        analytics: [
          { title: "qty_of_orders", enabled: true },
          { title: "transaction_amount", enabled: true },
          { title: "average_amount", enabled: false },
          { title: "custom_time", enabled: true }
        ],
        telegramBot: [
          { title: "subscriber", enabled: true, limit: "personal_free" },
          { title: "q&a_reply", enabled: false, discount: "discount_personal" },
          { title: "promotion_msg", enabled: true },
          { title: "lottery_tool", enabled: false },
          { title: "sigin_tool", enabled: false },
          { title: "point_tool", enabled: false },
          {
            title: "bookingTool",
            enabled: false,
            discount: "discount_personal"
          },
          {
            title: "reservationTool",
            enabled: false,
            discount: "discount_personal"
          },
          {
            title: "reserveTool",
            enabled: false,
            discount: "discount_personal"
          },
          {
            title: "preSaleTool",
            enabled: false,
            discount: "discount_personal"
          },
          {
            title: "liveSellingTool",
            enabled: false,
            discount: "discount_personal"
          },

          { title: "txtFee", enabled: true, limit: "free" },
          { title: "txtService", enabled: true, limit: "free" }
        ]
      },
      personalRegularPlan: {
        title: this.$t("__plan_personal_regular_title"),
        price: this.$t("__plan_regular_price") + "NT$52000",
        eplatForm: [
          { title: "chatroom", enabled: true },
          { title: "onepageweb", enabled: true },
          { title: "onepageweb_individual", enabled: false },
          { title: "backend_manage", enabled: true },
          { title: "chia_domain", enabled: true },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_ad", enabled: false },
          { title: "chat_market_model", enabled: false },
          { title: "onepageweb_home", enabled: false },
          { title: "onepageweb_home_individual", enabled: false }
        ],
        paymentMethod: [
          { title: "cash", enabled: true },
          { title: "cash_on_delivery", enabled: true },
          { title: "ATM", enabled: true },
          // { title: "tappay", enabled: true },
          { title: "ecpay", enabled: true },
          // { title: "paypal", enabled: false },
          { title: "google_pay", enabled: true },
          { title: "apple_pay", enabled: true },
          { title: "samsung_pay", enabled: true },
          { title: "store_bar_code", enabled: true },
          { title: "store_cash_on_delivery", enabled: true },
          { title: "credit_card", enabled: true },
          { title: "credit_card_staging", enabled: true }
        ],
        shippingChannel: [
          { title: "pickup", enabled: true },
          { title: "logistics", enabled: true },
          { title: "post", enabled: false },
          { title: "store_to_store", enabled: false },
          { title: "virtual_item", enabled: true },
          { title: "logistics_url_setting", enabled: true }
        ],
        consumerFrontend: [
          { title: "muti_language", enabled: true },
          { title: "rwd", enabled: true },
          { title: "live_video", enabled: false },
          { title: "onepageweb", enabled: true },
          { title: "promotion_setting", enabled: false },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_activities", enabled: false },
          { title: "telegram_store", enabled: true }
        ],
        backendManage: [
          { title: "telegram_login", enabled: false },
          { title: "publish_item_limit", enabled: true, limit: "unlimited" },
          { title: "rwd", enabled: true },
          // { title: "muti_mananger", enabled: false },
          // { title: "authority_manangement", enabled: false },
          { title: "ssl", enabled: true },
          { title: "promotion_notify", enabled: true },
          { title: "consumer_privacy", enabled: true },
          { title: "item_setting", enabled: true },
          { title: "order_tracking", enabled: false },
          { title: "seo_setting", enabled: true },
          // { title: "customer_service_setting", enabled: false },
          // { title: "e-invoice", enabled: true },
          { title: "order_notify", enabled: true }
        ],
        promotion: [
          { title: "membership_level", enabled: false },
          { title: "promotion_time", enabled: false },
          { title: "choose", enabled: false },
          { title: "combo", enabled: false },
          { title: "free_shipping", enabled: false },
          { title: "price_free_shipping", enabled: false },
          { title: "price_gift", enabled: false },
          { title: "price_discount", enabled: false },
          { title: "add_special", enabled: false },
          { title: "time_discount", enabled: false },
          { title: "coupon", enabled: false }
        ],
        ad_analytics: [
          { title: "google", enabled: false },
          { title: "google_ec", enabled: false },
          { title: "google_ads", enabled: false },
          { title: "google_ads_marketing", enabled: false },
          { title: "google_gtm", enabled: false },
          { title: "google_shoping_ads", enabled: false },
          { title: "facebook", enabled: false },
          { title: "facebook_dpa", enabled: false }
        ],
        analytics: [
          { title: "qty_of_orders", enabled: true },
          { title: "transaction_amount", enabled: true },
          { title: "average_amount", enabled: false },
          { title: "custom_time", enabled: true }
        ],
        telegramBot: [
          { title: "subscriber", enabled: true, limit: "unlimited" },
          { title: "q&a_reply", enabled: false, discount: "personal_regular" },
          { title: "promotion_msg", enabled: true },
          { title: "lottery_tool", enabled: false },
          { title: "sigin_tool", enabled: false },
          { title: "point_tool", enabled: false },
          {
            title: "bookingTool",
            enabled: false,
            discount: "personal_regular"
          },
          {
            title: "reservationTool",
            enabled: false,
            discount: "personal_regular"
          },
          {
            title: "reserveTool",
            enabled: false,
            discount: "personal_regular"
          },
          {
            title: "preSaleTool",
            enabled: false,
            discount: "personal_regular"
          },
          {
            title: "liveSellingTool",
            enabled: false,
            discount: "personal_regular"
          },
          { title: "keyWordSearch", enabled: false },
          { title: "customer_service", enabled: false },
          { title: "txtFee", enabled: true, limit: "free" },
          { title: "txtService", enabled: true, limit: "free" }
        ]
      },
      businessFreePlan: {
        title: this.$t("__plan_business_free_title"),
        eplatForm: [
          { title: "chatroom", enabled: true },
          { title: "onepageweb", enabled: true },
          // { title: "onepageweb_individual", enabled: false },
          { title: "backend_manage", enabled: true },
          { title: "chia_domain", enabled: true },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_ad", enabled: false },
          { title: "chat_market_model", enabled: false },
          { title: "onepageweb_home", enabled: false }
          // { title: "onepageweb_home_individual", enabled: false }
        ],
        paymentMethod: [
          { title: "cash", enabled: true },
          { title: "cash_on_delivery", enabled: true },
          { title: "ATM", enabled: true }
          // { title: "tappay", enabled: true },
          // { title: "ecpay", enabled: true },
          // { title: "paypal", enabled: false },
          // { title: "google_pay", enabled: true },
          // { title: "apple_pay", enabled: true },
          // { title: "samsung_pay", enabled: true },
          // { title: "store_bar_code", enabled: true },
          // { title: "store_cash_on_delivery", enabled: true },
          // { title: "credit_card", enabled: true },
          // { title: "credit_card_staging", enabled: true }
        ],
        shippingChannel: [
          { title: "pickup", enabled: true },
          { title: "logistics", enabled: true },
          { title: "post", enabled: false },
          // { title: "store_to_store", enabled: false },
          { title: "virtual_item", enabled: true },
          { title: "logistics_url_setting", enabled: true }
        ],
        consumerFrontend: [
          { title: "muti_language", enabled: true },
          { title: "rwd", enabled: true },
          { title: "live_video", enabled: false },
          { title: "onepageweb", enabled: true },
          { title: "promotion_setting", enabled: false },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_activities", enabled: false },
          { title: "telegram_store", enabled: true }
        ],
        backendManage: [
          { title: "telegram_login", enabled: false },
          {
            title: "publish_item_limit",
            enabled: true,
            limit: "business_free"
          },
          { title: "rwd", enabled: true },
          // { title: "muti_mananger", enabled: false },
          // { title: "authority_manangement", enabled: false },
          { title: "ssl", enabled: true },
          { title: "promotion_notify", enabled: true },
          { title: "consumer_privacy", enabled: true },
          { title: "item_setting", enabled: true },
          // { title: "order_tracking", enabled: false },
          { title: "seo_setting", enabled: true },
          // { title: "customer_service_setting", enabled: false },
          // { title: "e-invoice", enabled: true },
          { title: "order_notify", enabled: true }
        ],
        promotion: [
          // { title: "membership_level", enabled: false },
          { title: "promotion_time", enabled: false },
          // { title: "choose", enabled: false },
          // { title: "combo", enabled: false },
          { title: "free_shipping", enabled: false },
          // { title: "price_free_shipping", enabled: false },
          // { title: "price_gift", enabled: false },
          { title: "price_discount", enabled: false }
          // { title: "add_special", enabled: false },
          // { title: "time_discount", enabled: false },
          // { title: "coupon", enabled: false }
        ],
        analytics: [
          { title: "qty_of_orders", enabled: true },
          { title: "transaction_amount", enabled: true },
          { title: "average_amount", enabled: false },
          { title: "custom_time", enabled: true }
        ],
        telegramBot: [
          { title: "subscriber", enabled: true, limit: "business_free" },
          { title: "q&a_reply", enabled: false, discount: "discount_business" },
          { title: "promotion_msg", enabled: true },
          { title: "lottery_tool", enabled: false },
          { title: "sigin_tool", enabled: false },
          { title: "point_tool", enabled: false },
          {
            title: "bookingTool",
            enabled: false,
            discount: "discount_business"
          },
          {
            title: "reservationTool",
            enabled: false,
            discount: "discount_business"
          },
          {
            title: "reserveTool",
            enabled: false,
            discount: "discount_business"
          },
          {
            title: "preSaleTool",
            enabled: false,
            discount: "discount_business"
          },
          {
            title: "liveSellingTool",
            enabled: false,
            discount: "discount_business"
          },
          {
            title: "keyWordSearch",
            enabled: false,
            discount: "discount_business"
          },
          {
            title: "customer_service",
            enabled: false,
            discount: "discount_business"
          },
          { title: "txtFee", enabled: true, limit: "free" },
          { title: "txtService", enabled: true, limit: "free" }
        ]
      },
      businessRegularPlan: {
        title: this.$t("__plan_business_regular_title"),
        price: this.$t("__plan_regular_price") + "NT$60000",
        eplatForm: [
          { title: "chatroom", enabled: true },
          { title: "onepageweb", enabled: true },
          { title: "onepageweb_individual", enabled: false },
          { title: "backend_manage", enabled: true },
          { title: "chia_domain", enabled: true },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_ad", enabled: false },
          { title: "chat_market_model", enabled: false },
          { title: "onepageweb_home", enabled: false },
          { title: "onepageweb_home_individual", enabled: false }
        ],
        paymentMethod: [
          { title: "cash", enabled: true },
          { title: "cash_on_delivery", enabled: true },
          { title: "ATM", enabled: true },
          { title: "tappay", enabled: true },
          { title: "ecpay", enabled: true },
          { title: "paypal", enabled: false },
          { title: "google_pay", enabled: true },
          { title: "apple_pay", enabled: true },
          { title: "samsung_pay", enabled: true },
          { title: "store_bar_code", enabled: true },
          { title: "store_cash_on_delivery", enabled: true },
          { title: "credit_card", enabled: true },
          { title: "credit_card_staging", enabled: true }
        ],
        shippingChannel: [
          { title: "pickup", enabled: true },
          { title: "logistics", enabled: true },
          { title: "post", enabled: false },
          { title: "store_to_store", enabled: false },
          { title: "virtual_item", enabled: true },
          { title: "logistics_url_setting", enabled: true }
        ],
        consumerFrontend: [
          { title: "muti_language", enabled: true },
          { title: "rwd", enabled: true },
          { title: "live_video", enabled: false },
          { title: "onepageweb", enabled: true },
          { title: "promotion_setting", enabled: false },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_activities", enabled: false },
          { title: "telegram_store", enabled: true }
        ],
        backendManage: [
          { title: "telegram_login", enabled: false },
          { title: "publish_item_limit", enabled: true, limit: "unlimited" },
          { title: "rwd", enabled: true },
          { title: "muti_mananger", enabled: false },
          { title: "authority_manangement", enabled: false },
          { title: "ssl", enabled: true },
          { title: "promotion_notify", enabled: true },
          { title: "consumer_privacy", enabled: true },
          { title: "item_setting", enabled: true },
          { title: "order_tracking", enabled: false },
          { title: "seo_setting", enabled: true },
          { title: "customer_service_setting", enabled: false },
          { title: "e-invoice", enabled: true },
          { title: "order_notify", enabled: true }
        ],
        promotion: [
          { title: "membership_level", enabled: false },
          { title: "promotion_time", enabled: false },
          { title: "choose", enabled: false },
          { title: "combo", enabled: false },
          { title: "free_shipping", enabled: false },
          { title: "price_free_shipping", enabled: false },
          { title: "price_gift", enabled: false },
          { title: "price_discount", enabled: false },
          { title: "add_special", enabled: false },
          { title: "time_discount", enabled: false },
          { title: "coupon", enabled: false }
        ],
        ad_analytics: [
          { title: "google", enabled: false },
          { title: "google_ec", enabled: false },
          { title: "google_ads", enabled: false },
          { title: "google_ads_marketing", enabled: false },
          { title: "google_gtm", enabled: false },
          { title: "google_shoping_ads", enabled: false },
          { title: "facebook", enabled: false },
          { title: "facebook_dpa", enabled: false }
        ],
        analytics: [
          { title: "qty_of_orders", enabled: true },
          { title: "transaction_amount", enabled: true },
          { title: "average_amount", enabled: false },
          { title: "custom_time", enabled: true }
        ],
        telegramBot: [
          { title: "subscriber", enabled: true, limit: "unlimited" },
          { title: "q&a_reply", enabled: false },
          { title: "promotion_msg", enabled: true },
          { title: "lottery_tool", enabled: false },
          { title: "sigin_tool", enabled: false },
          { title: "point_tool", enabled: false },
          { title: "bookingTool", enabled: false },
          { title: "reservationTool", enabled: false },
          { title: "reserveTool", enabled: false },
          { title: "preSaleTool", enabled: false },
          { title: "liveSellingTool", enabled: false },
          { title: "keyWordSearch", enabled: false },
          { title: "customer_service", enabled: false },
          { title: "txtFee", enabled: true, limit: "free" },
          { title: "txtService", enabled: true, limit: "free" }
        ]
      },
      ngoPlan: {
        title: this.$t("__plan_ngo_title"),
        eplatForm: [
          { title: "chatroom", enabled: true },
          { title: "onepageweb", enabled: true },
          { title: "onepageweb_individual", enabled: false },
          { title: "backend_manage", enabled: true },
          { title: "chia_domain", enabled: true },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_ad", enabled: false },
          { title: "chat_market_model", enabled: false },
          { title: "onepageweb_home", enabled: false },
          { title: "onepageweb_home_individual", enabled: false }
        ],
        paymentMethod: [
          { title: "cash", enabled: true },
          { title: "cash_on_delivery", enabled: true },
          { title: "ATM", enabled: true },
          { title: "tappay", enabled: true },
          { title: "ecpay", enabled: true },
          { title: "paypal", enabled: false },
          { title: "google_pay", enabled: true },
          { title: "apple_pay", enabled: true },
          { title: "samsung_pay", enabled: true },
          { title: "store_bar_code", enabled: true },
          { title: "store_cash_on_delivery", enabled: true },
          { title: "credit_card", enabled: true },
          { title: "credit_card_staging", enabled: true }
        ],
        shippingChannel: [
          { title: "pickup", enabled: true },
          { title: "logistics", enabled: true },
          { title: "post", enabled: false },
          { title: "store_to_store", enabled: false },
          { title: "virtual_item", enabled: true },
          { title: "logistics_url_setting", enabled: true }
        ],
        consumerFrontend: [
          { title: "muti_language", enabled: true },
          { title: "rwd", enabled: true },
          { title: "live_video", enabled: false },
          { title: "onepageweb", enabled: true },
          { title: "promotion_setting", enabled: false },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_activities", enabled: false },
          { title: "telegram_store", enabled: true }
        ],
        backendManage: [
          { title: "telegram_login", enabled: false },
          { title: "publish_item_limit", enabled: true, limit: "unlimited" },
          { title: "rwd", enabled: true },
          { title: "muti_mananger", enabled: false },
          { title: "authority_manangement", enabled: false },
          { title: "ssl", enabled: true },
          { title: "promotion_notify", enabled: true },
          { title: "consumer_privacy", enabled: true },
          { title: "item_setting", enabled: true },
          { title: "order_tracking", enabled: false },
          { title: "seo_setting", enabled: true },
          { title: "customer_service_setting", enabled: false },
          { title: "e-invoice", enabled: true },
          { title: "order_notify", enabled: true }
        ],
        promotion: [
          { title: "membership_level", enabled: false },
          { title: "promotion_time", enabled: false },
          { title: "choose", enabled: false },
          { title: "combo", enabled: false },
          { title: "free_shipping", enabled: false },
          { title: "price_free_shipping", enabled: false },
          { title: "price_gift", enabled: false },
          { title: "price_discount", enabled: false },
          { title: "add_special", enabled: false },
          { title: "time_discount", enabled: false },
          { title: "coupon", enabled: false }
        ],
        ad_analytics: [
          { title: "google", enabled: false },
          { title: "google_ec", enabled: false },
          { title: "google_ads", enabled: false },
          { title: "google_ads_marketing", enabled: false },
          { title: "google_gtm", enabled: false },
          { title: "google_shoping_ads", enabled: false },
          { title: "facebook", enabled: false },
          { title: "facebook_dpa", enabled: false }
        ],
        analytics: [
          { title: "qty_of_orders", enabled: true },
          { title: "transaction_amount", enabled: true },
          { title: "average_amount", enabled: false },
          { title: "custom_time", enabled: true }
        ],
        telegramBot: [
          { title: "subscriber", enabled: true, limit: "unlimited" },
          { title: "q&a_reply", enabled: false },
          { title: "promotion_msg", enabled: true },
          { title: "lottery_tool", enabled: false },
          { title: "sigin_tool", enabled: false },
          { title: "point_tool", enabled: false },
          { title: "bookingTool", enabled: false },
          { title: "reservationTool", enabled: false },
          { title: "reserveTool", enabled: false },
          { title: "preSaleTool", enabled: false },
          { title: "liveSellingTool", enabled: false },
          { title: "keyWordSearch", enabled: false },
          { title: "customer_service", enabled: false },
          { title: "txtFee", enabled: true, limit: "free" },
          { title: "txtService", enabled: true, limit: "free" }
        ]
      },
      initialPlan: {
        title: this.$t("__plan_initial_title"),
        price: this.$t("__plan_regular_price") + "NT$40000",
        eplatForm: [
          { title: "chatroom", enabled: true },
          { title: "onepageweb", enabled: true },
          { title: "onepageweb_individual", enabled: false },
          { title: "backend_manage", enabled: true },
          { title: "chia_domain", enabled: true },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_ad", enabled: false },
          { title: "chat_market_model", enabled: false },
          { title: "onepageweb_home", enabled: false },
          { title: "onepageweb_home_individual", enabled: false }
        ],
        paymentMethod: [
          { title: "cash", enabled: true },
          { title: "cash_on_delivery", enabled: true },
          { title: "ATM", enabled: true },
          { title: "tappay", enabled: true },
          { title: "ecpay", enabled: true },
          { title: "paypal", enabled: false },
          { title: "google_pay", enabled: true },
          { title: "apple_pay", enabled: true },
          { title: "samsung_pay", enabled: true },
          { title: "store_bar_code", enabled: true },
          { title: "store_cash_on_delivery", enabled: true },
          { title: "credit_card", enabled: true },
          { title: "credit_card_staging", enabled: true }
        ],
        shippingChannel: [
          { title: "pickup", enabled: true },
          { title: "logistics", enabled: true },
          { title: "post", enabled: false },
          { title: "store_to_store", enabled: false },
          { title: "virtual_item", enabled: true },
          { title: "logistics_url_setting", enabled: true }
        ],
        consumerFrontend: [
          { title: "muti_language", enabled: true },
          { title: "rwd", enabled: true },
          { title: "live_video", enabled: false },
          { title: "onepageweb", enabled: true },
          { title: "promotion_setting", enabled: false },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_activities", enabled: false },
          { title: "telegram_store", enabled: true }
        ],
        backendManage: [
          { title: "telegram_login", enabled: false },
          { title: "publish_item_limit", enabled: true, limit: "unlimited" },
          { title: "rwd", enabled: true },
          { title: "muti_mananger", enabled: false },
          { title: "authority_manangement", enabled: false },
          { title: "ssl", enabled: true },
          { title: "promotion_notify", enabled: true },
          { title: "consumer_privacy", enabled: true },
          { title: "item_setting", enabled: true },
          { title: "order_tracking", enabled: false },
          { title: "seo_setting", enabled: true },
          { title: "customer_service_setting", enabled: false },
          { title: "e-invoice", enabled: true },
          { title: "order_notify", enabled: true }
        ],
        promotion: [
          { title: "membership_level", enabled: false },
          { title: "promotion_time", enabled: false },
          { title: "choose", enabled: false },
          { title: "combo", enabled: false },
          { title: "free_shipping", enabled: false },
          { title: "price_free_shipping", enabled: false },
          { title: "price_gift", enabled: false },
          { title: "price_discount", enabled: false },
          { title: "add_special", enabled: false },
          { title: "time_discount", enabled: false },
          { title: "coupon", enabled: false }
        ],
        ad_analytics: [
          { title: "google", enabled: false },
          { title: "google_ec", enabled: false },
          { title: "google_ads", enabled: false },
          { title: "google_ads_marketing", enabled: false },
          { title: "google_gtm", enabled: false },
          { title: "google_shoping_ads", enabled: false },
          { title: "facebook", enabled: false },
          { title: "facebook_dpa", enabled: false }
        ],
        analytics: [
          { title: "qty_of_orders", enabled: true },
          { title: "transaction_amount", enabled: true },
          { title: "average_amount", enabled: false },
          { title: "custom_time", enabled: true }
        ],
        telegramBot: [
          { title: "subscriber", enabled: true, limit: "unlimited" },
          { title: "q&a_reply", enabled: false },
          { title: "promotion_msg", enabled: true },
          { title: "lottery_tool", enabled: false },
          { title: "sigin_tool", enabled: false },
          { title: "point_tool", enabled: false },
          { title: "bookingTool", enabled: false },
          { title: "reservationTool", enabled: false },
          { title: "reserveTool", enabled: false },
          { title: "preSaleTool", enabled: false },
          { title: "liveSellingTool", enabled: false },
          { title: "keyWordSearch", enabled: false },
          { title: "customer_service", enabled: false },
          { title: "txtFee", enabled: true, limit: "free" },
          { title: "txtService", enabled: true, limit: "free" }
        ]
      },
      chiaBrandPlan: {
        title: this.$t("__plan_chia_brand_title"),
        price: this.$t("__plan_regular_price") + "NT$37500",
        eplatForm: [
          { title: "chatroom", enabled: true },
          { title: "onepageweb", enabled: true },
          { title: "onepageweb_individual", enabled: false },
          { title: "backend_manage", enabled: true },
          { title: "chia_domain", enabled: true },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_ad", enabled: false },
          { title: "chat_market_model", enabled: false },
          { title: "onepageweb_home", enabled: false },
          { title: "onepageweb_home_individual", enabled: false }
        ],
        paymentMethod: [
          { title: "cash", enabled: true },
          { title: "cash_on_delivery", enabled: true },
          { title: "ATM", enabled: true },
          { title: "tappay", enabled: true },
          { title: "ecpay", enabled: true },
          { title: "paypal", enabled: false },
          { title: "google_pay", enabled: true },
          { title: "apple_pay", enabled: true },
          { title: "samsung_pay", enabled: true },
          { title: "store_bar_code", enabled: true },
          { title: "store_cash_on_delivery", enabled: true },
          { title: "credit_card", enabled: true },
          { title: "credit_card_staging", enabled: true }
        ],
        shippingChannel: [
          { title: "pickup", enabled: true },
          { title: "logistics", enabled: true },
          { title: "post", enabled: false },
          { title: "store_to_store", enabled: false },
          { title: "virtual_item", enabled: true },
          { title: "logistics_url_setting", enabled: true }
        ],
        consumerFrontend: [
          { title: "muti_language", enabled: true },
          { title: "rwd", enabled: true },
          { title: "live_video", enabled: false },
          { title: "onepageweb", enabled: true },
          { title: "promotion_setting", enabled: false },
          { title: "chia_entry", enabled: false },
          { title: "chia_entry_activities", enabled: false },
          { title: "telegram_store", enabled: true }
        ],
        backendManage: [
          { title: "telegram_login", enabled: false },
          { title: "publish_item_limit", enabled: true, limit: "unlimited" },
          { title: "rwd", enabled: true },
          { title: "muti_mananger", enabled: false },
          { title: "authority_manangement", enabled: false },
          { title: "ssl", enabled: true },
          { title: "promotion_notify", enabled: true },
          { title: "consumer_privacy", enabled: true },
          { title: "item_setting", enabled: true },
          { title: "order_tracking", enabled: false },
          { title: "seo_setting", enabled: true },
          { title: "customer_service_setting", enabled: false },
          { title: "e-invoice", enabled: true },
          { title: "order_notify", enabled: true }
        ],
        promotion: [
          { title: "membership_level", enabled: false },
          { title: "promotion_time", enabled: false },
          { title: "choose", enabled: false },
          { title: "combo", enabled: false },
          { title: "free_shipping", enabled: false },
          { title: "price_free_shipping", enabled: false },
          { title: "price_gift", enabled: false },
          { title: "price_discount", enabled: false },
          { title: "add_special", enabled: false },
          { title: "time_discount", enabled: false },
          { title: "coupon", enabled: false }
        ],
        ad_analytics: [
          { title: "google", enabled: false },
          { title: "google_ec", enabled: false },
          { title: "google_ads", enabled: false },
          { title: "google_ads_marketing", enabled: false },
          { title: "google_gtm", enabled: false },
          { title: "google_shoping_ads", enabled: false },
          { title: "facebook", enabled: false },
          { title: "facebook_dpa", enabled: false }
        ],
        analytics: [
          { title: "qty_of_orders", enabled: true },
          { title: "transaction_amount", enabled: true },
          { title: "average_amount", enabled: false },
          { title: "custom_time", enabled: true }
        ],
        telegramBot: [
          { title: "subscriber", enabled: true, limit: "unlimited" },
          { title: "q&a_reply", enabled: false },
          { title: "promotion_msg", enabled: true },
          { title: "lottery_tool", enabled: false },
          { title: "sigin_tool", enabled: false },
          { title: "point_tool", enabled: false },
          { title: "bookingTool", enabled: false },
          { title: "reservationTool", enabled: false },
          { title: "reserveTool", enabled: false },
          { title: "preSaleTool", enabled: false },
          { title: "liveSellingTool", enabled: false },
          { title: "keyWordSearch", enabled: false },
          { title: "customer_service", enabled: false },
          { title: "txtFee", enabled: true, limit: "free" },
          { title: "txtService", enabled: true, limit: "free" }
        ]
      }
    };
  },
  methods: {
    uppercaseInvitationCode() {
      this.form.invitationCode = this.form.invitationCode.toUpperCase();
    },
    isInvitationCodeValid() {
      return new Promise((resolve, reject) => {
        if (this.form.invitationCode) {
          return userAPI
            .isInvitationCodeExist("", this.form.invitationCode)
            .then(result => {
              resolve(!!result["is_exist"]);
            })
            .catch(err => reject(err));
        } else {
          resolve(true);
        }
      });
    },
    signUp() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$store.dispatch("setIsLoading", true);
        return this.isInvitationCodeValid()
          .then(isValid => {
            if (!isValid) {
              return Promise.reject(this.$t("__invitation_code_invalid"));
            }
          })
          .then(() => {
            return this.$store.dispatch("user/signUpWithEmailAndPassword", {
              email: this.form.email,
              password: this.form.password
            });
          })
          .then(() => {
            return this.$store.dispatch("user/updateUserName", {
              firstName: this.form.firstName,
              lastName: this.form.lastName
            });
          })
          .then(() => {
            return this.$store.dispatch(
              "user/updatePhoneNumber",
              this.form.phoneNumber
            );
          })
          .then(() => {
            if (this.form.invitationCode) {
              return this.$store.dispatch(
                "user/updateJoinInvitationCode",
                this.form.invitationCode
              );
            }
          })
          .then(() => {
            window.location.href = "https://apply.chia-market.com/sign-in";
          })
          .catch(err => {
            this.$store.dispatch("setIsLoading", false);
            const errCode = err.code;
            const errMessage = err.message;
            let errorMessage = "";
            if (errCode) {
              const tKey = `__firebase_error_${errCode}`;
              errorMessage = this.$t(tKey);
              if (errorMessage === tKey) {
                if (errMessage) {
                  errorMessage = errMessage;
                } else {
                  errorMessage = errCode;
                }
              }
            } else if (errMessage) {
              errorMessage = errMessage;
            } else {
              errorMessage = err;
            }
            this.error = errorMessage;
          });
      }
    }
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(val) {
        switch (val) {
          case "personal-free":
            this.showPlan = this.personalFreePlan;
            break;
          case "personal-regular":
            this.showPlan = this.personalRegularPlan;
            break;
          case "business-free":
            this.showPlan = this.businessFreePlan;
            break;
          case "business-regular":
            this.showPlan = this.businessRegularPlan;
            break;
          case "ngo-plan":
            this.showPlan = this.ngoPlan;
            break;
          case "initial-plan":
            this.showPlan = this.initialPlan;
            break;
          case "chia-brand":
            this.showPlan = this.chiaBrandPlan;
            break;
          default:
            break;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-block: 1em;

  th,
  td {
    border: 1px solid silver;
    padding: 8px;
  }
  th {
    width: 1%;
    white-space: nowrap;
    text-align: left;
    color: black;
  }
  td {
    color: black;
  }
  &__subrow_td {
    padding-left: 24px !important;
  }
}
</style>
